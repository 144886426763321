ion-toolbar#nav-header {
  --background: #ffffff00;
  --border-style: none;
  --padding-start: 1.25rem;
  --padding-end: 1.25rem;
  --padding-top: 1.25rem;
}

.sidemenu-overlay {
  background-color: rgb(102 102 102 / 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sidemenu-overlay{ 
  outline: 0!important;
 }

