.notification-tray-overlay{ 
  background-color: rgb(102 102 102 / 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}


.notification-tray-overlay{ 
  outline: 0!important;
 }
