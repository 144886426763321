@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Spartan';
  src: url('../../public/assets/fonts/LeagueSpartan-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Lato';
  src: url('../../public/assets/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Futura';
  src: url('../../public/assets/fonts/FuturaStd-Medium.otf');
}

@font-face {
  font-family: 'Futura';
  font-weight: bold;
  src: url('../../public/assets/fonts/FuturaStd-Heavy.otf');
}

@font-face {
  font-family: 'Futura';
  font-weight: lighter;
  src: url('../../public/assets/fonts/FuturaStd-Light.otf');
}



p,
h6,
h5,
h4,
span {
  font-family: Futura;
}
h1,
h2,
h3 {
  font-family: Futura;
  font-style: normal;
}

h2 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 140%;
}

h3 {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 170%;
}

h4 {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 150%;
}

h5 {
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
}

h6 {
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 150%;
}

  .swiper-pagination-bullet-active{
    background-color: rgb(102 102 102 )!important;
  }