@keyframes presentLogo {
  0% { 
      transform: translateY(75%);
      opacity: 0;
  }

  100% { 
      transform: translateY(0);
      opacity: 1;
  }
}

#login-page .shell-logo{ 
  animation-name: presentLogo;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);

}